// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBYKmnihDInDbmk3EFVU44-76NXfDknfDg",
  authDomain: "seagulljobs-75ed8.firebaseapp.com",
  projectId: "seagulljobs-75ed8",
  storageBucket: "seagulljobs-75ed8.appspot.com",
  messagingSenderId: "406937846355",
  appId: "1:406937846355:web:43b17996e4abf30ca5fca6",
  measurementId: "G-H4MLKT77W7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
//auth.appVerificationDisabledForTesting = true;
