import React, { useState, useEffect } from 'react';
import { Link ,Routes,Route,Router,Switch, useNavigate, useHistory } from 'react-router-dom';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import { auth } from "./firebase"; // Import firebase configuration
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { BsFillShieldLockFill, BsTelephoneFill } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
import OtpInput from "otp-input-react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast, Toaster } from "react-hot-toast";
const BASE_URL=process.env.REACT_APP_BASE_URL;
const Signup = () => {
  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [user, setUser] = useState(null);
  const [showBtn, setShowBtn] = useState("none");
	const [formData, setFormData] = useState({
		firstName: '',
    lastName: '',
		email: '',
    password:'',
		mobileNumber: ''
	  });
    const navigate = useNavigate();
    const [errorData, setErrorData] = useState('');
    const [successData, setSuccessData] = useState('');
    const [showModal, setShowModal] = useState(false);
   
	  const handleInputChange = (e) => {
		const { name, value, type, checked, files } = e.target;
    const newValue = (name === 'firstName' || name === 'lastName')
    ? value.charAt(0).toUpperCase() + value.slice(1)
    : value;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : type === 'file' ? files[0] : newValue
    }));
    //alert(newValue);
	  };
	  const handleSubmit = async (e) => {
		e.preventDefault();
		
		try {
		  const formDataToSend = new FormData();
		  for (const key in formData) {
			if (key === 'whatsappUpdates') {
				formDataToSend.append(key, formData[key] ? '1' : '0');
			  } else {
				formDataToSend.append(key, formData[key]);
			  }
		  }

		 // const response = await axios.post('http://localhost:8000/api/register', formDataToSend, {
       const response = await axios.post(`${BASE_URL}register`, formDataToSend, {
			headers: {
			  'Content-Type': 'multipart/form-data'
			}
		  });
      setErrorData("");
    //  setErrorData("Registration Success..");
      setShowModal(true);
		 // alert('Registration successful:', response.data);
     setTimeout(() => {
      setShowModal(false);
      navigate('/login');
    }, 4000);  
		  // Handle successful registration (e.g., show success message, redirect)
		} catch (error) {
		  console.log('Registration failed:', error.response ? error.response.data : error.message);
      setErrorData("Registration Failed.."+ error.response.data.message);
		  // Handle error (e.g., show error message)
		}
	  };
	  useEffect(() => {
		//console.log('Form data updated:', formData);
	  }, [formData]);
    const modalStyle = {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    };
  
    const modalContentStyle = {
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '8px',
      width: '300px',
      textAlign: 'center',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    };
    function onCaptchVerify() {
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(
          auth,
          "recaptcha-container",
          {
            size: "invisible",
            callback: (response) => {
              onSignup();
            },
            "expired-callback": () => {},
          }
        );
      }
    }
  
    function onSignup() {
      setLoading(true);
      onCaptchVerify();
  
      const appVerifier = window.recaptchaVerifier;
  
      const formatPh = "+" + ph;
  
      signInWithPhoneNumber(auth, formatPh, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setLoading(false);
          setShowOTP(true);
          toast.success("OTP sended successfully!");
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  
    function onOTPVerify() {
      setLoading(true);
      window.confirmationResult
        .confirm(otp)
        .then(async (res) => {
          console.log(res);
          setUser(res.user);
          setLoading(false);
          setFormData((prevFormData) => ({
            ...prevFormData,   // Spread the existing formData
            mobileNumber: ph ,  // Add the new OTP field
            password: ph   // Add the new OTP field
          }));
          setShowBtn("block")
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
    const showMobileNumberField = formData.firstName && formData.lastName && formData.email;
    

  return (
    <div>
        <Header />
        <section className="practise-part section-p">
        <div className="container">
			<div className="row align-items-center justify-content-center">
				<div className="col-lg-10">
					<p><b>Signup with OTP</b></p>
					<div className="logview">
						<div className="row">
							<div className="col-lg-8 p-0">
							  <div className="form-2-wrappers p-3 mt-3">
								
<form onSubmit={handleSubmit}>
  <div className="mb-3 form-box">
    <label>First Name</label>
    <input 
      type="text" 
      className="form-control" 
      name="firstName" 
      value={formData.firstName}
      onChange={handleInputChange}
      placeholder="Enter Your First Name" 
      required 
    />
  </div>
  <div className="mb-3 form-box">
    <label>Last Name</label>
    <input 
      type="text" 
      className="form-control" 
      name="lastName" 
      value={formData.lastName}
      onChange={handleInputChange}
      placeholder="Enter Your Last Name" 
      required 
    />
  </div>
  <div className="mb-3 form-box">
    <label>Email ID</label>
    <input 
      type="email" 
      className="form-control" 
      name="email" 
      value={formData.email}
      onChange={handleInputChange}
      placeholder="Enter Your email" 
      required 
    />
    <span style={{fontSize:"10px"}}>Recruiter Will send Notifications to this Email</span>
  </div>
  {/* <div className="mb-3 form-box">
    <label>Password</label>
    <input 
      type="password" 
      className="form-control" 
      name="password" 
      value={formData.password}
      onChange={handleInputChange}
      placeholder="Enter Your Password" 
      required 
      minLength={8}
    />
  </div> */}
  {/* <div className="mb-3 form-box" >
    <label>Mobile Number</label>
    <input 
      type="text" 
      className="form-control" 
      name="mobileNumber" 
      value={formData.mobileNumber}
      onChange={handleInputChange}
      placeholder="Enter Your Number" 
      required 
    />
    
    <span style={{fontSize:"10px"}}>Recruiter Will Call you on this Number</span>
  </div> */}
    {showMobileNumberField && (
  <div className="mb-3 form-box">
  <div>
      <Toaster toastOptions={{ duration: 4000 }} />
      <div id="recaptcha-container"></div>
      {user ? (
        <h2 className="text-center font-medium text-2xl">
          👍Your Mobile Number Verified. Please Click Register to Complete the Process.
        </h2>
      ) : (
        <div className="w-80 flex flex-col gap-4 rounded-lg p-4">
        
          {showOTP ? (
            <>
              <div className="bg-white text-emerald-500 w-fit mx-auto p-4 rounded-full">
                <BsFillShieldLockFill size={30} />
              </div>
              <label
                htmlFor="otp"
                className="font-bold text-xl  text-center"
              >
                Enter your OTP
              </label>
              <OtpInput
                value={otp}
                onChange={setOtp}
                OTPLength={6}
                otpType="number"
                disabled={false}
                autoFocus
                className="opt-container "
              ></OtpInput><br /><br />
              <button type="button"
                onClick={onSignup}
                className="btn w-60 mb-2"
                style={{ backgroundColor: '#3498db', color: '#fff' }}
              >
                {loading && (
                  <CgSpinner size={20} className="mt-1 animate-spin" />
                )}
                <span>Resend OTP</span>
              </button>
              <button type="button"
                onClick={onOTPVerify}
                className="btn btn-outline-secondary login-btn w-60 mb-2"
              >
                {loading && (
                  <CgSpinner size={20} className="mt-1 animate-spin" />
                )}
                <span >Verify OTP</span>
              </button>
              
            </>
          ) : (
            <>
              <div className="bg-white text-emerald-500 w-fit mx-auto p-4 rounded-full" style={{display:"none"}}>
                <BsTelephoneFill size={30} />
              </div>
              <label
                htmlFor=""
                className="font-bold text-xl  text-center"
              >
                Verify your phone number
              </label>
              <PhoneInput country={"in"} value={ph} onChange={setPh} />
             
             
              <span style={{fontSize:"10px"}}>Recruiter Will Call you on this Number</span>
              <br /><br />
              <button type="button"
                onClick={onSignup}
                className="btn btn-outline-secondary login-btn w-60 mb-2"
              >
                {loading && (
                  <CgSpinner size={20} className="mt-1 animate-spin" />
                )}
                <span>Send OTP</span>
              </button>
            </>
          )}
        </div>
      )}
    </div>
    </div>
  )}
  <div className="mb-3 form-box" style={{display:showBtn}}>
    <p>By Clicking Register, you agree to the <b>Terms and Conditions & Privacy Policy</b> of Seagulljobs4u.com</p>
  </div>
  <button type="submit" className="btn btn-outline-secondary login-btn mb-2" style={{display:showBtn}}>Register Now</button>
  <h5 className="text-center mb-3 pb-3" style={{color:"red",fontSize:"20px"}}>{errorData}</h5>
</form>

							  </div>
							</div>
							<div className="col-lg-4 p-5">
								<div className="social-logins  type--A">
									{/* <div className="vrline">
									<h5 className="text-center">or</h5>
									</div> 
									
									<h4 className="mb-2 text-center">Continue With</h4> */}
									{/* <GoogleLoginButton />
									<FacebookLoginButton /> */}
									{/* <button className="btn btn-outline-secondary w-100 mb-2"><i className="fa fa-google text-danger"> </i> Google</button>
									<button className="btn btn-outline-secondary w-100  mb-2"><i className="fa fa-facebook-f text-primary"> </i> Facebook</button> */}
								</div>
							</div>
							
						  </div>	
					</div>
				</div>
			</div>
		</div>
    </section>
    <Footer />
    {showModal && (
        <div style={modalStyle}>
          <div style={modalContentStyle}>
            <h3>Registration Success</h3>
            <p>Hi, Your Registration is successful </p>
          </div>
        </div>
      )}
    </div>
  )
}

export default Signup