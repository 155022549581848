// src/components/PhoneAuth.js

import React, { useState, useEffect } from "react";
import { auth } from "./firebase"; // Import firebase configuration
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { BsFillShieldLockFill, BsTelephoneFill } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
import OtpInput from "otp-input-react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast, Toaster } from "react-hot-toast";
const BASE_URL=process.env.REACT_APP_BASE_URL;
//import { setupRecaptcha } from "./recaptcha"; // Import setupRecaptcha

function PhoneAuth() {
  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [user, setUser] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedDesignations, setSelectedDesignations] = useState([]);
  const [options, setOptions] = useState([]);
  useEffect(() => {
    // Fetch designations from the server
    const fetchDesignations = async () => {
      try {
        const response = await fetch(`${BASE_URL}getDesignations`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setOptions(data); // Populate options with fetched designations
        } else {
          console.error('Failed to fetch designations');
        }
      } catch (error) {
        console.error('Error fetching designations:', error);
      }
    };

    fetchDesignations();
  }, []);
  // Handler for checkbox change
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    const id = parseInt(value, 10); // Convert the value to an integer to match the ID type

    if (checked) {
      // Add the option if it's checked
      setSelectedOptions((prev) => [...prev, id]);
    } else {
      // Remove the option if it's unchecked
      setSelectedOptions((prev) => prev.filter((optionId) => optionId !== id));
    }
  };

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": () => {},
        }
      );
    }
  }

  function onSignup() {
    setLoading(true);
    onCaptchVerify();

    const appVerifier = window.recaptchaVerifier;

    const formatPh = "+" + ph;

    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(false);
        setShowOTP(true);
        toast.success("OTP sended successfully!");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  function onOTPVerify() {
    setLoading(true);
    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        console.log(res);
        setUser(res.user);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }
const horiz={
  marginTop: '10px',
  padding: '10px',
  border: '1px solid #ccc',
  borderRadius: '5px',
  maxWidth: '300px', // Set a fixed width
  maxHeight: '150px', // Set a fixed height to enable scrolling
  overflowY: 'auto', // Enable vertical scrollbar
};
  return (
    <section className="bg-emerald-500 flex items-center justify-center h-screen">
    <div>
      <Toaster toastOptions={{ duration: 4000 }} />
      <div id="recaptcha-container"></div>
      {user ? (
        <h2 className="text-center font-medium text-2xl">
          👍Login Success
        </h2>
      ) : (
        <div className="w-80 flex flex-col gap-4 rounded-lg p-4">
          <h1 className="text-center leading-normal  font-medium text-3xl mb-6">
            Welcome to <br /> CODE A PROGRAM
          </h1>
          

          <label>Select Options:</label>
      <div
        style={horiz}
      >
        {/* Render checkboxes dynamically based on options */}
        {options.map((option, index) => (
          <div key={index} style={{ margin: '5px 0' }}>
            <input
              type="checkbox"
              id={`option-${index}`}
              value={option.id}
              checked={selectedOptions.includes(option.id)}
              onChange={handleCheckboxChange}
            />
            <label htmlFor={`option-${index}`} style={{ marginLeft: '5px', cursor: 'pointer' }}>
              {option.designation}
            </label>
          </div>
        ))}
      </div>

      <div>
        <h4>Selected Options:</h4>
        <ul>
          {selectedOptions.map((optionId, index) => (
            <li key={index}>{optionId}</li>
          ))}
        </ul>
      </div>



          {showOTP ? (
            <>
              <div className="bg-white text-emerald-500 w-fit mx-auto p-4 rounded-full">
                <BsFillShieldLockFill size={30} />
              </div>
              <label
                htmlFor="otp"
                className="font-bold text-xl  text-center"
              >
                Enter your OTP
              </label>
              <OtpInput
                value={otp}
                onChange={setOtp}
                OTPLength={6}
                otpType="number"
                disabled={false}
                autoFocus
                className="opt-container "
              ></OtpInput>
              <button
                onClick={onOTPVerify}
                className="bg-emerald-600 w-full flex gap-1 items-center justify-center py-2.5  rounded"
              >
                {loading && (
                  <CgSpinner size={20} className="mt-1 animate-spin" />
                )}
                <span>Verify OTP</span>
              </button>
            </>
          ) : (
            <>
              <div className="bg-white text-emerald-500 w-fit mx-auto p-4 rounded-full">
                <BsTelephoneFill size={30} />
              </div>
              <label
                htmlFor=""
                className="font-bold text-xl text-white text-center"
              >
                Verify your phone number
              </label>
              <PhoneInput country={"in"} value={ph} onChange={setPh} />
              <button
                onClick={onSignup}
                className="bg-blue-600 w-full flex gap-1 items-center justify-center py-2.5 rounded"
              >
                {loading && (
                  <CgSpinner size={20} className="mt-1 animate-spin" />
                )}
                <span >Send OTP via SMS</span>
              </button>
            </>
          )}
        </div>
      )}
    </div>
  </section>
);
};

export default PhoneAuth;
