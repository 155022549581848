import React, { useState,useEffect } from 'react';
import { Link ,Routes,Route,Router,Switch, useNavigate, useHistory } from 'react-router-dom';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import { auth } from "./firebase"; // Import firebase configuration
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { BsFillShieldLockFill, BsTelephoneFill } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
import OtpInput from "otp-input-react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast, Toaster } from "react-hot-toast";
const BASE_URL=process.env.REACT_APP_BASE_URL;
const Login = () => {
  const [errorData, setErrorData] = useState('');
  const [successData, setSuccessData] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [user, setUser] = useState(null);
  const [showBtn, setShowBtn] = useState("none");
  const [userData, setUserData] = useState(null);
	const [formData, setFormData] = useState({
		mobile: '',
		password: '',
	  });
  const navigate = useNavigate();
function setLogin(){
          setErrorData("");
          setShowModal(true);
        //await response.json();
        sessionStorage.setItem('candidateId', userData.candidate.id);
        sessionStorage.setItem('candidateFName', userData.candidate.firstname);
        const dob=userData.candidate.dob;
        sessionStorage.setItem('login', "yes");
        sessionStorage.setItem('user', JSON.stringify(userData));
        const jobs1=sessionStorage.getItem('jobs');
        const jobdetails1=sessionStorage.getItem('jobdetails');
        setTimeout(() => {
          setShowModal(false); // Hide the modal
          
            
        if(dob===null){
        navigate('/biodata');
        }
        else if(jobs1){
        if(jobdetails1){
        navigate('/jobdetails');
        }else{
        navigate('/');
        }
        }else{
        navigate('/');
        }
        }, 4000);  

}
  const handleSubmit = async (e) => {
    e.preventDefault();
    /*
    setFormData((prevFormData) => ({
      ...prevFormData,   // Spread the existing formData
      mobile: 918943716725 ,  // Add the new OTP field
      password: 918943716725   // Add the new OTP field
    }));
    */
    const updatedFormData = {
      mobile: ph,        // Set mobile field
      password: ph       // Set password field
    };
 //alert(JSON.stringify(updatedFormData))
   
    try {
   // alert(JSON.stringify(formData)) ; 
     // const response = await axios.post('http://localhost:8000/api/loginemp', formData);
   const response = await axios.post(`${BASE_URL}loginemp`, updatedFormData);
      //console.log(JSON.stringify(response));
      if(response.status===200){
        const data =response.data;
        setUserData(data);
       // setShowLoader(true);
       onSignup(); 
      }
      else{
        setUserData(null);
        setErrorData("Have Some Error, Try again..");
        sessionStorage.removeItem('login');  
        sessionStorage.removeItem('candidateId');
        sessionStorage.removeItem('candidateFName');
      }
    } catch (error) {
   setErrorData("Login Process,Please Wait..");
      sessionStorage.setItem('login', 'no');
      //alert("Login Failed due to " + error.response ? error.response.data : error.message);
     // console.error('Login failed:', error.response ? error.response.data : error.message);
      // Handle login error (e.g., show error message)
    }
  };
  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": () => {},
        }
      );
    }
  }

  function onSignup() {
    setLoading(true);
    onCaptchVerify();

    const appVerifier = window.recaptchaVerifier;

    const formatPh = "+" + ph;

    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(false);
        setShowOTP(true);
        toast.success("OTP sended successfully!");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  function onOTPVerify() {
    setLoading(true);
    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        console.log(res);
        setUser(res.user);
        setLoading(false);

        setLogin();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  const modalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  };

  const modalContentStyle = {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    width: '300px',
    textAlign: 'center',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  };
  return (
    <div>
        <body class="home-3">
        <Header />
        <section className="practise-part section-p pt-2">
        <div className="container">
			<div className="row align-items-center justify-content-center">
				<div className="col-lg-8">
					<div className="logview">
						<div className="row">
							<div className="col-lg-6 p-0 bg-banner">
								<div className="reservation-card p-4 h-100">
									<div className="reservation-card-container p-6">
									   <div className="reservation-card-inner p-sm-6">
										  <h3 className="text-white fw-600">We are glad to <br />see you again!</h3>
									   </div>
									</div>
								 </div>
							</div>

							<div className="col-lg-6 p-0">
							  <div className="form-2-wrapper">
								
								<h3 className="text-center mb-4">Login with OTP</h3>
								<form onSubmit={handleSubmit}>
      <div className="mb-3 form-box">
      <div>
      <Toaster toastOptions={{ duration: 4000 }} />
      <div id="recaptcha-container"></div>
      {user ? (
        <h5 className="text-center font-medium text-2xl">
          👍Your Mobile Number Verified.
        </h5>
      ) : (
        <div className="w-80 flex flex-col gap-4 rounded-lg p-4">
        
          {showOTP ? (
            <>
              <div className="bg-white text-emerald-500 w-fit mx-auto p-4 rounded-full">
                <BsFillShieldLockFill size={30} />
              </div>
              <label
                htmlFor="otp"
                className="font-bold text-xl  text-center"
              >
                Enter your OTP
              </label>
              <OtpInput
                value={otp}
                onChange={setOtp}
                OTPLength={6}
                otpType="number"
                disabled={false}
                autoFocus
                className="opt-container "
              ></OtpInput><br /><br />
              <button type="submit" 
              className="btn w-60 mb-2"
              style={{ backgroundColor: '#3498db', color: '#fff' }}
              >
      {loading && (
                  <CgSpinner size={20} className="mt-1 animate-spin" />
                )}
                <span>Resend OTP</span>
      </button>
              <button type="button"
                onClick={onOTPVerify}
                className="btn btn-outline-secondary login-btn w-60 mb-2"
              >
                {loading && (
                  <CgSpinner size={20} className="mt-1 animate-spin" />
                )}
                <span >Verify OTP</span>
              </button>
              
              
            </>
          ) : (
            <>
              <div className="bg-white text-emerald-500 w-fit mx-auto p-4 rounded-full" style={{display:"none"}}>
                <BsTelephoneFill size={30} />
              </div>
              <label
                htmlFor=""
                className="font-bold text-xl  text-center"
              >
                Login to Verify your phone number
              </label>
              <PhoneInput country={"in"} value={ph} onChange={setPh} />
             
              {/* <button type="button"
                onClick={onSignup}
                className="bg-blue-600 w-full flex gap-1 items-center justify-center py-2.5 rounded"
              >
                {loading && (
                  <CgSpinner size={20} className="mt-1 animate-spin" />
                )}
                <span>Login to Send OTP via SMS</span>
              </button> */}<br /><br />
              <button type="submit" className="btn btn-outline-secondary login-btn w-100 mb-2">
      {loading && (
                  <CgSpinner size={20} className="mt-1 animate-spin" />
                )}
                <span>Login to Send OTP</span>
      </button>
              
            </>
          )}
        </div>
      )}
    </div>
      </div>
      
      
      
      <h5 className="text-center mb-3 pb-3" style={{color:"red"}}>{errorData}</h5>
      {/* {showLoader && <div className="loader">Loading...</div>} */}
      <div className="social-login type--A">
        <hr />
        <h5 className="text-center">or</h5>
        {/* <button className="btn btn-outline-secondary mb-2" type="button">
          <i className="fa fa-google text-danger"></i> Sign With Google
        </button>
        <button className="btn btn-outline-secondary mb-2" type="button">
          <i className="fa fa-facebook-f text-primary"></i> Sign With Facebook
        </button> */}
      </div>
    </form>

								<p className="text-center register-test mt-3">Don't have an account? <a href="/signup" className="text-decoration-none">Sign Up</a></p>
							  </div>
							</div>
						  </div>	
					</div>
				</div>
			</div>
		</div>
    </section>
    <Footer />
    {/* <AlertModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title={alertTitle}
        message={alertMessage}
      /> */}
    </body>
    {showModal && (
        <div style={modalStyle}>
          <div style={modalContentStyle}>
            <h3>Login Success</h3>
            <p>Hi, You have successfully logged in.</p>
          </div>
        </div>
      )}
    </div>
  )
}

export default Login